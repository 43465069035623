<template>
  <base-panel icon="mdi-forum" :title="formTitle">
    <v-form
      ref="form"
      v-model="valid"
      @submit.prevent="saveItem"
      v-if="editedItem"
    >
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.name"
            prepend-icon="store"
            :label="$t('quizzes.fields.name')"
            :rules="rules.name"
            @change="resetValidation"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <prism-editor
            v-if="form"
            v-model="code"
            language="js"
            class="my-editor height-300"
            :highlight="highlighter"
            :line-numbers="false"
            @input="resetValidation"
          ></prism-editor>
          <v-alert v-if="serverErrors && serverErrors.questions" type="error">
            {{ $t(serverErrors.questions[0]) }}
          </v-alert>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          type="submit"
          :disabled="sending || savedItem != null"
          color="primary"
          >{{ $t("Save") }}</v-btn
        >
      </v-card-actions>
    </v-form>
    <v-progress-linear
      v-if="sending"
      color="deep-purple accent-4"
      indeterminate
      rounded
      height="6"
    ></v-progress-linear>
    <v-alert v-if="errorMessage" tile class="mb-0" type="error">{{
      $t(errorMessage)
    }}</v-alert>
    <v-alert v-if="savedItem" tile type="success" class="mb-0">{{
      $t("quizzes.saved", savedItem)
    }}</v-alert>
  </base-panel>
</template>

<script>
import crudMixin from "mixins/crudForm";
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere
// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css"; // import syntax highlighting styles
//import validate from "@/plugins/validate";

export default {
  mixins: [crudMixin],
  components: { PrismEditor },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      apiQuery: "/quizzes/",
      reloadBeforeEdit: true,
      code: "",
      defaultItem: {
        name: "",
      },
      rules: {
        name: [
          (v) =>
            !!v ||
            this.$t("validation.required", {
              name: this.$t("quizzes.fields.name"),
            }),
          () =>
            this.getInputError("name", {
              min: 2,
              name: this.$t("quizzes.fields.name"),
            }),
          ...this.defaultValidation("name", this.$t("quizzes.fields.name")),
        ],
      },
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.editItem({ id: this.id }, 0);
  },

  computed: {
    formTitle() {
      return this.editedItem === null
        ? ""
        : this.$t("quizzes.edit") + " - " + this.editedItem.name;
    },
  },
  methods: {
    saveItem() {
      this.errorMessage = null;
      this.serverErrors = null;
      this.form.questions = this.code;
      if (!this.$refs.form.validate()) {
        return;
      }

      this.patch(this.apiQuery + this.form.id, this.form)
        .then((res) => {
          this.savedItem = res;
          this.$emit("itemSaved", res, this.editedIndex);
        })
        .catch((error) => {
          this.errorMessage = error.message || error.error;
          this.serverErrors = error.errors;
          this.$refs.form.validate();
          this.$emit("serverError", error);
        });
    },
    resetValidation() {
      this.savedItem = null;
    },
    highlighter(code) {
      return highlight(code, languages.js); //returns html
    },
    onDataLoaded() {
      this.code = this.editedItem.questions;
    },
  },
};
</script>

<style lang="scss">
// required class
.my-editor {
  background: #2d2d2d;
  color: #ccc;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

// optional
.prism-editor__textarea:focus {
  outline: none;
}

.height-300 {
  height: 300px;
}
</style>
